<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <h2>Case {{ getCase.FileNumber }}</h2>
    <hr>
    <b-row>
      <b-col>
        <h2>Add Action</h2>
        <div class="white-bg-container">
          <b-row>
            <b-col md="5">
              <b-form-group
                label="Action Type"
                label-for="actionType"
              >
                <v-select
                  id="actionType"
                  v-model="ActionType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="actionTypeOption"
                  placeholder=""
                  name="actionType"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Description"
                label-for="body"
              >
                <div
                  id="description"
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedDescription}"
                >
                  <textarea
                    v-model="Description"
                    class="expandable-textarea"
                    :class="[isExpandedDescription ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedDescription = !isExpandedDescription">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedDescription ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group
                label="Authorized Hours"
                label-for="authorizedHours"
              >
                <b-form-input
                  id="authorizedHours"
                  v-model="AuthorizedHours"
                  type="number"
                  step="0.01"
                  min="0"
                  placeholder=""
                  name="authorizedHours"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Time Window Start"
                label-for="timeWindowStart"
              >
                <b-form-input
                  id="timeWindowStart"
                  v-model="TimeWindowStart"
                  type="time"
                  placeholder=""
                  name="timeWindowStart"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Time Window End"
                label-for="timeWindowEnd"
              >
                <b-form-input
                  id="timeWindowEnd"
                  v-model="TimeWindowEnd"
                  type="time"
                  placeholder=""
                  name="timeWindowEnd"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group
                label="Upsell"
                label-for="upsell"
              >
                <b-form-checkbox
                  id="upsell"
                  v-model="Upsell"
                  name="upsell"
                  :disabled="readOnly"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group
                label="Must Go"
                label-for="mustGo"
                class="text-nowrap"
              >
                <b-form-checkbox
                  id="mustGo"
                  v-model="MustGo"
                  name="mustGo"
                  :disabled="readOnly"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group
                label="Hold"
                label-for="hold"
              >
                <b-form-checkbox
                  id="hold"
                  v-model="Hold"
                  name="hold"
                  :disabled="readOnly"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Travel Required"
                label-for="travelRequired"
              >
                <b-form-checkbox
                  id="travelRequired"
                  v-model="isTravelRequired"
                  name="travelRequired"
                  :disabled="readOnly"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Daily Instructions"
                label-for="body"
              >
                <div
                  id="dailyInstructions"
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedDailyInstructions}"
                >
                  <textarea
                    v-model="DailyInstructions"
                    class="expandable-textarea"
                    :class="[isExpandedDailyInstructions ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpandedDailyInstructions = !isExpandedDailyInstructions">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedDailyInstructions ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          :disabled="disabledSaveBtn"
          @click="saveForm()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import { required } from '@validations'

const apiService = new APIService();

export default {
  components: {
    BFormCheckbox,
    BBreadcrumb,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActionType: "",
      Description: "",
      AuthorizedHours: "",
      TimeWindowStart: "",
      TimeWindowEnd: "",
      ScheduledDate: "",
      DailyInstructions: "",
      CaseID: "",
      Upsell: false,
      MustGo: false,
      Hold: false,
      isTravelRequired: false,

      actionTypeOption: [],

      readOnly: false,
      disabledSaveBtn: false,
      isExpandedDescription: true,
      isExpandedDailyInstructions: true,

      required,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getCurrentUsers: "users/getCurrentUsers",
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.getCase.FileNumber,
        },
      ]
    },
  },
  watch: {},
  async created() {
    try {
      this.CaseID = this.$route.params.caseID;
      apiService
          .get("action/types")
          .then((response) => {
            this.actionTypeOption = response.data.map(item => {
              return {title: item.Name, value: item.Name, id: item.ActionTypeID}
            });
            this.actionTypeOption.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
          });
    } catch (err) {
      this.error = err;
    }
  },
  mounted() {
    const currentTime = new Date();
    currentTime.setDate(currentTime.getDate()+14);
    this.ScheduledDate = currentTime.toISOString().split('T')[0];
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    resetForm() {
      this.$router.push('/cases/' + this.CaseID + "/detail/ACTIONS");
    },
    saveForm() {
      this.disabledSaveBtn = true;
      let postData = {
        "CaseID": this.CaseID || null,
        "ActionTypeID": this.ActionType.id || null,
        "AuthorizedHours": this.AuthorizedHours || null,
        "TimeWindowStart": this.TimeWindowStart || null,
        "TimeWindowEnd": this.TimeWindowEnd || null,
        "Title": this.Description || null,
        "DailyInstructions": this.DailyInstructions || null,
        "Upsell": this.Upsell,
        "MustGo": this.MustGo,
        "Hold": this.Hold,
        "isTravelRequired": this.isTravelRequired,
      }
      apiService.post("action/create", postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Action has been saved!');
          this.$router.push('/cases/' + this.CaseID + "/detail/ACTIONS");
        }
        this.disabledSaveBtn = false;
      })
    },
  }
}
</script>

<style scoped>

</style>